import * as React from 'react';
import { IContainerProps } from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './RibbonAreaSkin.scss';

const RibbonAreaSkin: React.FC<IContainerProps> = props => {
  return (
    <ContainerLogic
      {...props}
      className={styles.root}
      renderSlot={({ containerChildren }) => (
        <>
          <div className={styles.flRibbon} />
          <div className={styles.frRibbon} />
          <div className={styles.bg} />
          {containerChildren}
        </>
      )}
    />
  );
};

export default RibbonAreaSkin;
